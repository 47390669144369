<template>
  <q-avatar
    v-if="!isImage"
    :icon="name"
    :color="colorC"
    :class="inverted ? 'text-white' : ''"
    :square="!rounded"
    :size="size"
  />
  <q-avatar
    v-else-if="!rounded"
    :size="size"
  >
    <img
      :src="icon"
      :height="size"
      :style="{ minWidth: size }"
    >
  </q-avatar>
  <q-img
    v-else
    :src="icon"
    :height="size"
    :style="{ minWidth: size }"
    contain
  />
</template>

<script type="text/javascript">
import travelContents from 'mixins/travelContents'
export default {
  name: 'MIconsAvatar',
  mixins: [travelContents],
  props: {
    type: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: 'help'
    },
    isImage: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: null
    },
    rounded: {
      type: Boolean,
      default: true
    },
    inverted: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    name () {
      if (this.type) {
        var icon = this.getTravelContentIcon(this.type)
        return icon || 'far fa-question-circle'
      }
      return this.icon
    },
    colorC () {
      if (this.type) {
        var color = this.getTravelContentColor(this.type)
        return color || 'grey'
      }
      return this.color
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-icon, .q-img
    display inline-flex
    width 100%
    height 100%
    font-size 250%
  .rounded
    border-radius 100% !important
</style>
